exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aranjuez-js": () => import("./../../../src/pages/aranjuez.js" /* webpackChunkName: "component---src-pages-aranjuez-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-madrid-js": () => import("./../../../src/pages/madrid.js" /* webpackChunkName: "component---src-pages-madrid-js" */),
  "component---src-pages-toledo-js": () => import("./../../../src/pages/toledo.js" /* webpackChunkName: "component---src-pages-toledo-js" */)
}

